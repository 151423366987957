<!-- 上传汇款凭证弹窗 -->
<template>
  <el-dialog
    width="50%"
    center
    :visible="visible"
    :lock-scroll="false"
    :destroy-on-close="true"
    custom-class="ele-dialog-form"
    :title="isUpdate?'上传汇款凭证':'上传汇款凭证'"
    @update:visible="updateVisible">
    <el-form
      ref="form"
      :model="form"
      label-width="130px">

      <div style="border-bottom: 1px solid #eeeeee;">
        <div style="display: flex;align-items: center;">
          <span style="width: 5px;height: 19px;background: #ff9b05;display: inline-block;margin-right: 10px;"></span>
          <span style="font-size: 18px;font-weight: 700;color: #3f4155;">账单信息</span>
        </div>
        <el-row :gutter="15" style="margin-top: 15px;">
          <el-col :span="12">
            <el-form-item label="客户名称：">
              <span>{{details.info.channel_name}}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="对账周期：">
              <span v-for="(item,index) in details.info.period" :key="index" style="margin-right: 10px;">{{item}}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="15">
          <el-col :span="12">
            <el-form-item label="订单总量：">
              <span>{{details.info.order_count}}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="合同价：">
              <span>{{details.info.contract_price}}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="15">
          <el-col :span="12">
            <el-form-item label="整体补扣款原因：">
              <span>{{details.info.entirety_repair_reason}}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="应收款：">
              <span style="color: #FF9B05;">{{details.info.receivable}}</span>
            </el-form-item>
          </el-col>
        </el-row>
      </div>

      <div style="margin-top: 20px;">
        <div style="display: flex;align-items: center;">
          <span style="width: 5px;height: 19px;background: #ff9b05;display: inline-block;margin-right: 10px;"></span>
          <span style="font-size: 18px;font-weight: 700;color: #3f4155;">付款信息</span>
        </div>
        <el-row :gutter="15" style="margin-top: 15px;">
          <el-col :span="12">
            <el-form-item label="开户行：">
              <span>{{details.payment_proof.tax_number}}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="户名：">
              <span>{{details.payment_proof.bank_number}}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="15">
          <el-col :span="12">
            <el-form-item label="账号：">
              <span>{{details.payment_proof.account_holder}}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="发票：">
              <div style="display: flex;align-items: center;" @click="open()">
                <span style="color: #FF9B05;">下载</span>
                <img src="@/assets/images/home/caiwu/xiazai.png" alt="" style="width: 23px;height: 21px;margin-left: 15px;">
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </div>

      <div style="margin-top: 20px;">
        <div style="display: flex;align-items: center;">
          <span style="width: 5px;height: 19px;background: #ff9b05;display: inline-block;margin-right: 10px;"></span>
          <span style="font-size: 18px;font-weight: 700;color: #3f4155;">上传付款凭证</span>
          <span style="font-size: 14px;font-weight: 400;color: #7E7F8C;margin-left: 20px;">*可上传pdf/jpg/png文件</span>
        </div>
        <div style="margin-top: 10px;">
          <!--accept=".jpg, .png, jpeg" 限制上传类型-->
          <el-upload
            class="upload-demo"
            :limit="1"
            :action="action"
            accept=".jpg, .png, jpeg"
            :headers="headers"
            :on-success="successUpload"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :before-remove="beforeRemove"
            multiple
            :on-exceed="handleExceed"
          >
            <div class="shangchuan" style="margin-left: 20px">
              <el-button class="custom-button">
                <i class="el-icon-shangchuan" />
                <span style="vertical-align: middle">点击上传</span>
              </el-button>
            </div>
          </el-upload>
          <!--列表-->
          <!--<div style="margin-top: 20px;">-->
          <!--  <div style="background: #f7f8fa;border-radius: 6px;padding: 10px;">-->
          <!--    <div style="display: flex;align-items: center;justify-content: space-between;">-->
          <!--      <div style="display: flex;align-items: center;">-->
          <!--        <img src="@/assets/images/home/caiwu/icon.png" alt="" style="width: 21px;height: 24px;margin-right: 15px;">-->
          <!--        <span style="font-size: 14px;font-weight: 400;color: #242832;">海信集团有限公司.jpg</span>-->
          <!--      </div>-->
          <!--      <div>-->
          <!--        <img src="@/assets/images/home/caiwu/del.png" alt="" style="width: 11px;height: 11px;cursor: pointer;">-->
          <!--      </div>-->
          <!--    </div>-->
          <!--  </div>-->
          <!--</div>-->

        </div>
      </div>

    </el-form>


    <div slot="footer">
      <el-button
        @click="updateVisible(false)">取消
      </el-button>
      <el-button
        style="margin-left: 20px;"
        type="primary"
        :loading="loading"
        @click="save">确定
      </el-button>
    </div>

  </el-dialog>
</template>

<script>
// 获取配置
import setting from '@/config/setting'
import {getCookies} from '@/utils/cookies'
import Config from '@/config'
import {
  capacity_reconciliationget_payment_proof,
  capacity_reconciliationupload_payment,
  get_payment_proof,
  upload_payment
} from "@/api/finance";
export default {
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  components: { },
  data() {
    return {
      // 表单数据
      form: Object.assign({}, this.data),
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,

      // 上传接口
      action: setting.apiBaseURL + 'common/upload/finance',
      // 上传请求头部
      headers:{
        'Authori-Zation' : 'Bearer' + ' ' + getCookies(Config.tokenStoreName)
      },
      // 上传假数据展示
      fileList: [],

      //
      details:{},

    };
  },
  mounted() {
    if (this.data) {
      console.log(this.data)
      this.isUpdate = true;
    } else {
      this.form = {};
      this.isUpdate = false;
    }

    this.getDetails();

  },
  methods: {
    //获取付款信息
    getDetails(){
      capacity_reconciliationget_payment_proof(this.data.id).then(res => {
        this.details = res.data;
      })
    },

    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          let data = {
            payment_proof:this.form.payment_proof
          }
          capacity_reconciliationupload_payment(this.data.id,data).then(res => {
            console.log(res)
            this.loading = false;
            if (res.code === 200) {
              this.$message.success(res.msg);
              if (!this.isUpdate) {
                this.form = {};
              }
              this.updateVisible(false);
              this.$emit('done');
            } else {
              this.$message.error(res.msg);
            }
          }).catch(e => {
            this.loading = false;
            this.$message.error(e.msg);
          })
        } else {
          return false;
        }
      });
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },

    //发票下载
    open(){
      window.open(this.details.info.invoice);
    },

    //上传事件
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${ file.name }？`);
    },
    //上传成功
    successUpload(file,fileList){
      console.log(file)
      // 上传成功后的列表
      this.form.payment_proof = file.data.urls;
    },


  }
}
</script>

<style scoped lang="scss">

</style>
